<template>
    <div>
        <!-- <img class="shadow-logo" alt="Gin Logo Loader" src="../assets/logo.svg">
        <img alt="Gin Logo Loader" src="../assets/logo.svg"> -->
        <!-- <svg class="shadow-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276.99 275.15"><title>Gin</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M211,19.57l14.75,79q1.42,8.25,3.21,18.91t4,24.6l3.58,23.39q-1.92-34.57-2.91-59.16t-1-41.28V36A139.13,139.13,0,0,0,211,19.57Z"/><path d="M265.73,82.8V192.36a138.88,138.88,0,0,0,0-109.56Z"/><path d="M227.69,243.52l-12.57-70.27q-2.75-15.66-5-29.33t-3.87-26q1.08,28,1.59,48.84t.49,36.46v54A139.57,139.57,0,0,0,227.69,243.52Z"/><path d="M11.34,139.37q0-37.89,4.58-66.3a138.69,138.69,0,0,0-.62,127.84Q11.36,174.21,11.34,139.37Z"/><path d="M96.67,238.77q-5.49,16.74-13.38,25.85a137.47,137.47,0,0,0,39.26,10.53V0A137.73,137.73,0,0,0,82.34,11Q96.71,26.67,105,66.27L71.36,91.73q-.75-12.56-3.45-19.87t-6.79-7.31q-14.31,0-14.32,81.35,0,34.57,3.17,50.22T60,211.77A6.42,6.42,0,0,0,65,209a22.87,22.87,0,0,0,3.79-7.74,71.24,71.24,0,0,0,2.42-12.12,132.42,132.42,0,0,0,.87-15.91H59.29v-55.9h48.45v11Q107.74,205.07,96.67,238.77Z"/><path d="M175.25,270.93h0V5.39h0V4a139,139,0,0,0-18-3.65V274.8a138.65,138.65,0,0,0,18-3.67Z"/></g></g></svg> -->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276.99 275.15"><title>Gin</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M211,19.57l14.75,79q1.42,8.25,3.21,18.91t4,24.6l3.58,23.39q-1.92-34.57-2.91-59.16t-1-41.28V36A139.13,139.13,0,0,0,211,19.57Z"/><path d="M265.73,82.8V192.36a138.88,138.88,0,0,0,0-109.56Z"/><path d="M227.69,243.52l-12.57-70.27q-2.75-15.66-5-29.33t-3.87-26q1.08,28,1.59,48.84t.49,36.46v54A139.57,139.57,0,0,0,227.69,243.52Z"/><path d="M11.34,139.37q0-37.89,4.58-66.3a138.69,138.69,0,0,0-.62,127.84Q11.36,174.21,11.34,139.37Z"/><path d="M96.67,238.77q-5.49,16.74-13.38,25.85a137.47,137.47,0,0,0,39.26,10.53V0A137.73,137.73,0,0,0,82.34,11Q96.71,26.67,105,66.27L71.36,91.73q-.75-12.56-3.45-19.87t-6.79-7.31q-14.31,0-14.32,81.35,0,34.57,3.17,50.22T60,211.77A6.42,6.42,0,0,0,65,209a22.87,22.87,0,0,0,3.79-7.74,71.24,71.24,0,0,0,2.42-12.12,132.42,132.42,0,0,0,.87-15.91H59.29v-55.9h48.45v11Q107.74,205.07,96.67,238.77Z"/><path d="M175.25,270.93h0V5.39h0V4a139,139,0,0,0-18-3.65V274.8a138.65,138.65,0,0,0,18-3.67Z"/></g></g></svg>
    </div>
</template>
<script>
export default {
    name: "Loader"
}
</script>
<style scoped>
div {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

svg {
    width: 360px;
    max-width: 100%;
    animation:spin 2s linear infinite;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
@keyframes spin {
    100% {
        /* -webkit-transform: rotate(360deg); */
        transform:rotate(360deg); 
    }
}
/* @keyframes intervalspin {
    100% {
        transform:rotate(360deg); 
    }
} */

</style>